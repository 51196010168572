import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnchorLink from 'react-anchor-link-smooth-scroll'
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import FeatureWithSteps from "components/features/OptdinFeaturesWithSteps.js";
import MainFeature from "components/features/TwoColSingleFeatureWithStats2.js";

import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
// import macHeroScreenshotImageSrc from "images/optdin/illustrations/social.svg"
import macHeroScreenshotImageSrc from "images/hero-screenshot-2.png";
import AppMockup from 'images/app-mockup.png';

// import Hero from "components/hero/OptdinHeroUnit.js";
import Hero from "components/hero/OptdinHeroFullCentered.js";
import Features from "components/features/OptdinFeatures3Cols.js";
import chefIconImageSrc from "images/chef-icon.svg";
import celebrationIconImageSrc from "images/celebration-icon.svg";
import shopIconImageSrc from "images/shop-icon.svg";

import eatWithFriends from 'images/optdin/illustrations/eat-with-friends.svg'
import enjoyUnique from 'images/optdin/illustrations/enjoy-unique.svg'
import earnMoney from 'images/optdin/illustrations/earn-money.svg'

import Testimonial from 'components/testimonials/OptdinTestimonialSecondary';
import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
import Blog from "components/blogs/OptdinAboutBlog.js";
import FAQ from "components/faqs/OptdinFaqsSingleCol.js";
import DownloadApp from 'components/cta/OptdinDownloadApp';
import InterestedBanner from 'components/cta/OptdinInterested';
import ContactUsForm from "components/forms/OptdinContactUs.js";
import Footer from "components/footers/OptdinFooter.js";
import faqsIllustrationSrc from "images/optdin/illustrations/faqs.svg";

export default () => {
  
  //FeaturesWithSteps
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;
  
  return(

  <AnimationRevealPage>
    <Hero />
    {/* <MainFeature /> */}
    <Features
        subheading="FEATURES"
        heading={
          <>
            Why use <HighlightedText>Optdin?</HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: eatWithFriends,
            title: "Eat with new or existing friends",
            description: "We bring together great people for incredible dining experiences",
            url: "#"
          },
          {
            imageSrc: enjoyUnique,
            title: "Enjoy unique dining experiences",
            description: "Whether it’s Mexican fiesta at your friends flat or dim sum at a pop-up restaurant, expand your horizons with Optdin",
            url: "#"
          },
          {
            imageSrc: earnMoney,
            title: "Earn money from your culinary skills",
            description: "Follow your passion and take your food dream to the next level",
            url: "#"
          }
        ]}

        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      />
    <Blog />
    {/* <Testimonial/> */}
    {/* <FeatureWithSteps
        subheading={<Subheading>Get Started</Subheading>}
        heading={
          <>
            Get started with <HighlightedText>Optdin.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={AppMockup}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      /> */}
    {/* <InterestedBanner/> */}
    <DownloadApp/>
    <FAQ
      imageSrc={faqsIllustrationSrc}
      imageContain={true}
      imageShadow={false}
      subheading="FAQs"
      heading={
        <>
          Still have <span tw="text-primary-500">questions?</span>
        </>
      }
    />
    <ContactUsForm />
    <Footer />
  </AnimationRevealPage>
)};
