import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionDescription } from "components/misc/Typography.js";

import Header, { NavLink, NavLinks, PrimaryLink as PrimaryLinkBase, LogoLink, NavToggle, DesktopNavLinks } from "../headers/OptdinHeader.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-primary-500 hover:text-gray-100`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`
const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover h-screen min-h-144`}
  background-image: url("https://images.unsplash.com/photo-1530062845289-9109b2c9c868?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1352&q=80&auto=format&fit=crop&w=1920&q=80");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div` flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-3xl text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-200 leading-snug  sm:mt-0`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const Darker = tw.span`text-primary-500`;

const Description = tw(SectionDescription)`mt-4 text-center lg:text-base text-white max-w-lg`;

const PrimaryAction = tw.button`rounded-full px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-gray-100 font-bold shadow transition duration-300 bg-primary-500 text-gray-100 hocus:bg-gray-200 hocus:text-primary-500 focus:outline-none focus:shadow-outline`;

export default () => {
  // const navLinks = [
  //   <NavLinks key={1}>
  //     <NavLink href="#">
  //       About
  //     </NavLink>
  //     <NavLink href="#">
  //       Blog
  //     </NavLink>
  //     <NavLink href="#">
  //       Locations
  //     </NavLink>
  //     <NavLink href="#">
  //       Pricing
  //     </NavLink>
  //   </NavLinks>,
  //   <NavLinks key={2}>
  //     <PrimaryLink href="/#">
  //       Sign Up for the Beta
  //     </PrimaryLink>
  //   </NavLinks>
  // ];

  return (
    <Container>
      <OpacityOverlay />
      <HeroContainer>
        <StyledHeader/>
        <Content>
          <Heading>
              Host dinner parties<Darker>.</Darker> 
          </Heading>
          <Heading>
              Dine with friends<Darker>.</Darker>   
          </Heading>
          <Description>Optdin connects passionate cooks with people who are looking to discover authentic homemade food with friends.</Description>
          <PrimaryAction>Search Events Near Me</PrimaryAction>
        </Content>
      </HeroContainer>
    </Container>
  );
};
